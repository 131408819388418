import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"

import Layout from "../components/layout"

const GalleryImage = ({ pageContext }) => {
  const intl = useIntl()
  const lang = intl.locale
  const { galleryName, galleryTitle, fluid, previous, next } = pageContext
  const galleryUrl =
    lang !== "en" ? `/${lang}/${galleryName}` : `/${galleryName}`
  const previousUrl = previous && `${galleryUrl}/${previous}`
  const nextUrl = next && `${galleryUrl}/${next}`

  useEffect(() => {
    const handler = e => {
      switch (e.key) {
        case "ArrowLeft":
          if (previousUrl) navigate(previousUrl)
          break
        case "ArrowRight":
          if (nextUrl) navigate(nextUrl)
          break
        case "Escape":
          navigate(galleryUrl)
          break
        default:
          break
      }
    }

    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  })

  return (
    <Layout title={galleryTitle} galleryImage closeUrl={galleryUrl}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        `}
      >
        <div
          css={css`
            width: 90%;
            margin: 0px auto;
            border: 1px solid rgba(0, 0, 0, 0.09);
          `}
        >
          <Img fluid={fluid} />
        </div>
        <div
          css={css`
            direction: ltr;
            display: flex;
            justify-content: space-between;
            width: 150px;
            margin: 0px auto;
            padding: 20px;
            a {
              padding: 2px;
              color: #157a10;
            }
          `}
        >
          <div>
            {(previousUrl && <Link to={previousUrl}>previous</Link>) ||
              "previous"}
          </div>
          <div>/</div>
          <div>{(nextUrl && <Link to={nextUrl}>next</Link>) || "next"}</div>
        </div>
      </div>
    </Layout>
  )
}

GalleryImage.propTypes = {
  pageContext: PropTypes.shape({
    previous: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired,
    fluid: PropTypes.object.isRequired,
    galleryName: PropTypes.string.isRequired,
    galleryTitle: PropTypes.string.isRequired,
  }).isRequired,
}

export default GalleryImage
